<template>
    <div class="contact-info-section pt-100 pb-100">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>Contact Information</small>
                <h2>Let's Talk</h2>
                <p>Creating a Safer Community with AZAI TECH Through AI-Powered Solutions</p>
            </div>
            <!-- Contact-info -->
            <div class="contact-info mb-15">
                <div class="row">
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-location-pin"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Location</h3>
                                    <p>Visit Our Office</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>5 Zakir Farzaliyev Street,</p>
                                <p>Baku, Azerbaijan</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-ui-call"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Make A Call</h3>
                                    <p>Let’s Talk with our Experts</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>
                                    <a href="tel:(+994) 10 250 20 80">(+994) 10 250 20 80</a>
                                </p>
                                <p>
                                    <a href="tel:(+994) 12 460 90 00">(+994) 12 460 90 00</a>
                                </p>
                                <p>Mon - Fri: 09.00 to 18.00</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 pb-30 offset-lg-0 offset-md-3">
                        <div class="contact-info-item">
                            <div class="contact-info-header">
                                <div class="contact-info-header-icon">
                                    <i class="icofont-email"></i>
                                </div>
                                <div class="contact-info-header-text">
                                    <h3>Send An Email</h3>
                                    <p>Don’t Hesitate to Email</p>
                                </div>
                            </div>
                            <div class="contact-info-body">
                                <p>
                                    <a href="mailto:info@lada.com">info@azai.tech</a>
                                </p>
                                <p>
                                    <a href="mailto:support@lada.com">support@azai.tech</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Contact-info -->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ContactInfo'
}
</script>