<template>
    <div>
        <div class="service-section pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 pb-30">
                        <div class="forum-details desk-pad-right-40">
                           
                            <h2>"Introducing Revolutionary Smart Glasses: See the Future Clearly"</h2>

                            
                           

                            <p>We are proud to present our revolutionary smart glasses, designed to enhance your life with advanced features and seamless connectivity. Our smart glasses bring a new dimension to your vision, enabling you to see the world like never before.</p>
                            <ul>
                                <li>Advanced Face Detection</li>
                               
                                <li>Sleek and Lightweight Design</li>
                                <li>Seamless Connectivity</li>
                            </ul>
                          

                            <br/>
                            <br/>
                            <br/>
                              

                                <Carousel :itemsToShow="3" :wrapAround="true" :transition="500">
                                    <Slide v-for="slide in 10" :key="slide">
                                     <img :src="require(`../../assets/images/projects/a${slide }.jpeg`)" alt="photo">
                                    </Slide>
                                  

                                  <template #addons>
                                    <Navigation/>
                                    <Pagination />
                                  </template>
                                </Carousel>
                            
                                <br/>
                                <br/>
                                <br/>
                                

                                <h3>Discover a new way of seeing the world with our smart glasses! <br/> </h3>
                  

                           
                            
                                <video controls width="1200" height="700" >
                                  <source src="../../assets/images/projects/smart_glass.webm" type="video/mp4">
                             
                              </video>
    
                              <br/>
                              <br/>


                            <h3>Compatibility</h3>
                            <p>Our smart glasses are compatible with both iOS and Android devices, ensuring a wide range of users can benefit from the technology and features they offer.                            </p>
                            <h3>Privacy and Security</h3>
                            <p>We understand the importance of privacy and security in today's connected world. Our smart glasses prioritize your data protection, employing robust encryption measures and giving you full control over your personal information.                                .</p>
                            <h3>Support and Updates</h3>
                            <p>We are committed to providing excellent customer support and continuous updates to enhance your smart glasses' functionality. Our team is always available to assist you with any questions or concerns you may have.</p>
                            

                         

                                 </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div 
            class="popup-video" 
            :class="{ show: active }"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/Cx5aNwnZYDc" title="How will Artificial Intelligence and Internet of Things change the world?" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'

import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
    name: 'SmartGlassDetails',
    components: {
        Carousel,
        Slide,
        Pagination,
        Navigation
    },
    data (){
        return {
            active: false,
            button_active_state: false,

        }
    },
}
</script>

<style scoped>


.carousel__slide {
  padding: 5px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1.1);
}
</style>