<template>
    <div>
        <div class="service-section pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 pb-30">
                        <div class="forum-details desk-pad-right-40">
                           
                        
                            <h2>"Efficient Vehicle Counting from Stream Video: Optimize Traffic Management"</h2>
                            <p>We bring innovation to traffic management with our cutting-edge vehicle counting solution. Our advanced technology utilizes stream video analysis to provide accurate and real-time vehicle counting, helping you optimize traffic flow and make informed decisions. Say goodbye to manual counting and embrace the power of automation with our vehicle counting solution.</p>
                            <ul>
                                <li>Real-Time Vehicle Counting</li>
                                <li>High Accuracy and Reliability</li>
                                <li>Customizable Reporting and Analytics</li>
                                
                            </ul>
                            <p>Experience the power of automated vehicle counting and take your traffic management strategies to the next level. </p>
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <video controls width="900" height="510" >
                                        <source src="../../assets/images/projects/lane_wise.webm" type="video/mp4">
                                   
                                    </video>
          
                                </div>
                              <!--  <div class="col-12 col-sm-6">
                                    <img src="../../assets/images/projects/peek1.jpg" alt="project">
                                </div>-->
                            </div>
                            <h3>Compatibility</h3>
                            <p>Our vehicle counting solution supports a wide range of cameras and video formats, making it compatible with most surveillance systems available on the market. It can be easily integrated with both new and existing infrastructure.                            </p>
                            <h3>Data Privacy and Security</h3>
                            <p>We prioritize the security and privacy of your data. Our solution employs robust encryption measures to protect sensitive information and ensures compliance with data protection regulations.</p>
                            <h3>Continuous Improvement</h3>
                            <p>We are committed to continuous improvement and innovation. As new technologies and algorithms emerge, we actively incorporate them into our solution, ensuring you benefit from the latest advancements in vehicle counting technology.</p>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div 
            class="popup-video" 
            :class="{ show: active }"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/Cx5aNwnZYDc" title="How will Artificial Intelligence and Internet of Things change the world?" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectsDetails',
    data (){
        return {
            active: false,
            button_active_state: false,
        }
    },
}
</script>