<template>
    <div class="about-section bg-off-white pt-10 pb-70">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-image text-center">
                        <img src="../../assets/images/feature-shape/feature-shape-1.png" alt="shape">
                    </div>
                </div>
                <div class="col-lg-6 pb-30">
                    <div class="about-section-item about-item-details">
                        <div class="section-title section-title-left text-start">
                            <small>About Us</small>
                            <h2>Mission Is To Bring The Power Of AI To Every Community</h2>
                        </div>
                        <div class="about-content">
                            <p>At AZAI Tech, our mission is to use AI technology to enhance human safety and security. We understand that technology plays a vital role in protecting communities, and we strive to develop innovative solutions that leverage the latest AI technology to detect, prevent, and respond to potential threats.</p>
                            <ul>
                                <li>Advance Advisory Team</li>
                                <li>AI technology</li>
                                <li>ML Technology</li>
                                <li>High-Quality Equipments</li>
                            </ul>
                          <!-- <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni.</p>
                            <router-link to="/about-us" class="btn main-btn">About Us</router-link>--> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>