<template>
    <div class="header header-bg-lg header-bg-shape border-bottom-1">
        <div class="container-fluid p-lg-0">
            <div class="row m-lg-0">
                <div class="col-lg-6 p-lg-0">
                    <div class="max-585 ms-auto">
                        <div class="header-content">
                            <h1>Azai tech</h1>
                            <p>Proin gravida nibh vel velit auctor aliquet. Aenean sollicitudin, lorem quis bibendum auctor, nisi elit consequat ipsum, nec sagittis sem nibh</p>
                            <div class="button-group button-group-animated">
                                <router-link to="/contact" class="btn main-btn">
                                    Get Started
                                </router-link>
                                <router-link to="/contact" class="btn main-btn main-btn-white">
                                    Start A Free Trial
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-image-shape">
            <img src="../../assets/images/homepage-one/header-shape.png" alt="shape">
        </div>
        <div class="header-animation-shapes animation-d-none">
            <div class="header-animation-shape"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>