<template>
    <div class="header header-bg header-bottom-space header-bg-image">
        <div class="container-fluid p-lg-0 custom-container-fluid">
            <div class="row m-lg-0 align-items-center">
                <div class="col-lg-6 p-lg-0">
                    <div class="max-585 ms-lg-auto">
                        <div class="header-content header-content-white m-0">
                            <h1>Revolutionizing Community Safety with AI Technology</h1>
                            <p>Our company places a strong emphasis on utilizing AI technologies in an ethical and responsible way that aligns with human values. We ensure that our AI solutions are developed and implemented in compliance with laws and regulations, and prioritize the protection of individuals' rights and privacy. </p>
                            <div class="button-group button-group-animated">
                        <!--      <router-link to="/contact" class="btn main-btn">
                                    Get Started
                                </router-link>
                                <router-link to="/contact" class="btn main-btn main-btn-white">
                                    Start A Free Trial
                                </router-link>-->  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>