<template>
    <div class="feature-section pt-min-100 pb-70">
        <div class="container">
            <div class="section-title section-title-lg">
                <h2>Any Sufficiently Advanced <br> Technology Is Indistinguishable From Magic</h2>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>ML Technology</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Proin gravida nibh vel velit auctor aliquet aenean sollicitudin.</p>
                                <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-blue">
                                    <i class="flaticon-machine-learning"></i>
                                </div>
                                <span>Data Tech</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Proin gravida nibh vel velit auctor aliquet aenean sollicitudin.</p>
                                <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-violet">
                                    <i class="flaticon-processor"></i>
                                </div>
                                <span>AI Technology</span>
                            </div>
                            <div class="feature-card-body"> 
                                <p>Proin gravida nibh vel velit auctor aliquet aenean sollicitudin.</p>
                                <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>