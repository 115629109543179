<template>
    <div class="service-section pt-100 pb-70">
        <div class="container">
            <div class="section-title section-title-lg">
                <small>Services</small>
                <h2>Our Purpose Is To Make Communities Safer <br> by using AI technology</h2>
                <p>We believe that the future of human safety and security lies in the integration of AI technology. We are committed to using AI to enhance human safety and security and make a meaningful impact on the communities we serve.</p>
            </div>
            <div class="row">
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-automation"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Intelligent Transport System (ITS)</h3>
                            <p>By providing real-time data and alerts, our product can help optimize traffic flow and reduce congestion, increase efficiency on the roads and improve safety for drivers, passengers and pedestrians.</p>
                           <!-- <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-predictive-chart"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Speed Enforcement</h3>
                            <p>Our state-of-the-art speed enforcement solutions help to reduce the incidence of speeding and reckless driving, making our roads safer for everyone.</p>
                           <!--<router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>--> 
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-deep-learning"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>CO2 Monitoring</h3>
                            <p>Our CO2 monitoring system in traffic allows us to track and measure carbon emissions from vehicles, enabling us to identify sources of high emissions and implement strategies to reduce them.</p>
                         <!--   <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-data-mining"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Automatic Number Plate Recognition (ANPR)</h3>
                            <p>(ANPR) technology enables us to identify and track vehicles on the road, allowing us to monitor traffic flow, enforce speed regulations, and improve road safety. </p>
                       <!--     <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-diagram"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Smart Traffic Lights</h3>
                            <p>Our product seamlessly integrates with existing traffic lights, making them smarter by utilizing advanced sensors, cameras, and algorithms to optimize traffic flow and enhance road safety. Cities should have a sustainable transportation system.</p>
                       <!--     <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>-->
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-4 pb-30">
                    <div class="service-card">
                        <div class="service-card-thumb">
                            <i class="flaticon-machine-learning-2"></i>
                        </div>
                        <div class="service-card-body">
                            <h3>Vehicle-to-Infrastructure (V2I) Communications</h3>
                            <p>(V2I) technology, allowing vehicles to communicate with the infrastructure such as traffic lights, road signs and traffic management systems. </p>
                       <!--     <router-link to="/single-services" class="redirect-link">
                                Discover More 
                                <i class="icofont-rounded-right"></i>
                            </router-link>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Services'
}
</script>