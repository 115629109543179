<template>
    <div class="feature-section section-to-header">
        <div class="container">
            <div class="container">
                <div class="row">
                    <div class="col-md-6 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-blue">
                                    <i class="flaticon-machine-learning"></i>
                                </div>
                                <span>Computer Vision</span>
                            </div>
                            <div class="feature-card-body">
                              <p>We use Computer Vision to enhance transportation safety and efficiency through real-time monitoring of roads, identifying and addressing potential hazards.</p>
                               <!--  <router-link to="/single-services" class="redirect-link">
                                    Discover More
                                    <i class="icofont-rounded-right"></i>
                                </router-link>--> 
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-green">
                                    <i class="flaticon-machine-learning-1"></i>
                                </div>
                                <span>AI Technology</span>
                            </div>
                            <div class="feature-card-body">
                              <p>We use AI Technology to improve transportation performance and reliability through advanced analytics, machine learning, and automation.</p>
                               <!--   <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>--> 
                            </div>
                        </div>
                    </div>
  
                  <!--  <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-violet">
                                    <i class="flaticon-processor"></i>
                                </div>
                                <span>Human Safety</span>
                            </div>
                            <div class="feature-card-body">
                               <p>Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.</p>
                               <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div>-->
              <!--      <div class="col-md-6 col-lg-3 pb-30">
                        <div class="feature-card">
                            <div class="feature-card-header">
                                <div class="feature-card-thumb feature-card-thumb-yellow">
                                    <i class="flaticon-voice-recognition"></i>
                                </div>
                                <span>Smart Team</span>
                            </div>
                            <div class="feature-card-body">
                                <p>Proin gravida nibh vel velit auctor aliquet Aenean sollicitudin.</p>
                                <router-link to="/single-services" class="redirect-link">
                                    Discover More 
                                    <i class="icofont-rounded-right"></i>
                                </router-link>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Feature'
}
</script>