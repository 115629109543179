<template>
    <div class="trial-section pt-min-100 pb-100 bg-main page-bg page-bg-1">
        <div class="container">
            <div class="section-title section-title-lg section-title-white mb-0">
                <h2>We Are Offering Demo</h2>
                <p>Our purpose is to deliver excellence in service and execution.</p>
                <router-link to="/contact" class="btn main-btn main-btn-white main-btn-rounded-icon">
                    Let's Talk 
                    <span><i class="icofont-rounded-right"></i></span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FreeTrial'
}
</script>