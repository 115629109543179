<template>
    <footer class="bg-off-white footer">
        <div class="footer-shapes">
            <div class="footer-shape">
                <img src="../../assets/images/shape-1.png" alt="shape">
            </div>
            <div class="footer-shape footer-round-shape">
                <img src="../../assets/images/shape-2.png" alt="shape">
            </div>
        </div>
        <div class="footer-upper pt-100 pb-80 position-relative">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-5">
                        <div class="footer-content-item">
                            <div class="footer-logo">
                                <router-link to="/">
                                    <img src="../../assets/images/logo6.png" alt="logo">
                                </router-link>
                            </div>
                            <div class="footer-details">
                                <p>We strive to develop innovative solutions that leverage the latest AI technology to detect, prevent, and respond to potential threats.</p>
                                <ul class="social-list social-list-btn">
                                  <!--  <li>
                                        <a href="https://www.facebook.com/" target="_blank">
                                            <i class="icofont-facebook"></i>
                                        </a>
                                    </li>-->
                                    <li>
                                        <a href="https://www.linkedin.com/company/azaitech/" target="_blank">
                                            <i class="icofont-linkedin"></i>
                                        </a>
                                    </li>
                                  <!--  <li>
                                        <a href="https://www.instagram.com/" target="_blank">
                                            <i class="icofont-instagram"></i>
                                        </a>
                                    </li>-->
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-6 col-lg-4">
                        <div class="footer-content-list footer-content-item desk-pad-left-40">
                            <div class="footer-content-title">
                                <h3>Products</h3>
                            </div>
                            <ul class="footer-details footer-list">
                            

                                <li><router-link to="/single-project">Radius</router-link></li>

                                <li><router-link to="/smart-glass-project">Smart Glass</router-link></li>

                                <li><router-link to="/lane-wise-project">Lane Wise</router-link></li>
                            </ul>
                        </div>
                    </div>
                  <!--  <div class="col-sm-6 col-md-6 col-lg-3">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Services</h3>
                            </div>
                            <ul class="footer-details footer-list">
                                <li><router-link to="/single-services">Robotic Automation</router-link></li>
                                <li><router-link to="/single-services">Predictive Analytic</router-link></li>
                                <li><router-link to="/single-services">Deep Learning</router-link></li>
                                <li><router-link to="/single-services">Statistic Modeling</router-link></li>
                                <li><router-link to="/single-services">Data Mining</router-link></li>
                                <li><router-link to="/single-services">Security & Surveillance</router-link></li>
                            </ul>
                        </div>
                    </div>-->
                    <div class="col-sm-6 col-md-6 col-lg-2">
                        <div class="footer-content-list footer-content-item desk-pad-left-70">
                            <div class="footer-content-title">
                                <h3>Contact</h3>
                            </div>
                            <div class="footer-details footer-address">
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Phone:</h4>
                                        <p><a href="/">(+994) 10 250 20 80</a></p>
                                        <p><a href="/">(+994) 12 460 90 00</a></p>
                                    </div>
                                </div>
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Email:</h4>
                                        <p><a href="/">info@azai.tech</a></p>
                                    </div>
                                </div>
                                <div class="footer-address-item">
                                    <div class="footer-address-text">
                                        <h4>Address:</h4>
                                        <p>5 Zakir Farzaliyev Street, Baku, Azerbaijan</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-lower bg-blue position-relative">
            <div class="container">
                <div class="footer-copyright-text footer-copyright-text-white">
                    <p>Copyright ©{{currentYear}} AZAI Tech. Designed & Developed By <a href="https://azai.tech/" target="_blank">AZAI Tech</a></p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>