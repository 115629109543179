<template>
    <div>
        <div class="service-section pt-100 pb-70">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 pb-30">
                        <div class="forum-details desk-pad-right-40">
                           
                        
                            <h2>"Ensuring Community Safety with our Advanced AI-based Speed Enforcement"</h2>
                            <p>The product aims to improve traffic safety and security by accurately detecting and enforcing speed limits, as well as identifying license plate numbers of vehicles, which can be used for tracking stolen vehicles, identifying vehicles involved in illegal activities, and enforcing speed regulations.</p>
                            <ul>
                                <li>Speed Detection</li>
                                <li>Plate Detection</li>
                                <li>ML Algorithms</li>
                                <li>High-Quality results</li>
                            </ul>
                            <p>Radius is a reliable and efficient way to improve traffic safety, reduce congestion and enhance the security of the community. Contact us to learn more about how our product can benefit your organization.</p>
                            <div class="row">
                                <div class="col-12 col-sm-6">
                                    <img src="../../assets/images/projects/peek.jpg" alt="project">
                                </div>
                              <!--  <div class="col-12 col-sm-6">
                                    <img src="../../assets/images/projects/peek1.jpg" alt="project">
                                </div>-->
                            </div>
                            <h3>Improved Safety</h3>
                            <p>By detecting and enforcing speed limits, our product helps reduce the likelihood of accidents caused by excessive speed, which can improve safety for drivers, passengers, and pedestrians.</p>
                            <h3>Enhanced Security</h3>
                            <p>Our license plate detection feature can be used to track stolen vehicles, identify vehicles involved in illegal activities, and enforce parking regulations, which can improve overall security for the community.</p>
                            <h3>Real-time monitoring:</h3>
                            <p>The AI-based system can provide real-time monitoring which can help in incident management, traffic control and can help in providing updates to the drivers.</p>
                            
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
        <div 
            class="popup-video" 
            :class="{ show: active }"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/Cx5aNwnZYDc" title="How will Artificial Intelligence and Internet of Things change the world?" frameborder="0" allowfullscreen></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProjectsDetails',
    data (){
        return {
            active: false,
            button_active_state: false,
        }
    },
}
</script>